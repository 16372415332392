<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { getAction, postAction, putAction, delAction } from '@/command/netTool'
import { message } from 'ant-design-vue'
import { del } from 'vue'

export default {
  name: 'physicalCouponReceive',
  data() {
    return {
      ...api.command.getState(),
      dataObj: {
        giveNum: 0,
        receivedTotal: 0,
        receivedTotalPercentage: 0,
        usedNum: 0,
        usedPercentage: 0,
        weekReceivedTotalNum: 0,
        weekUsedNum: 0,
      },
      duiwuCouponId: null,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const { id, turntableId } = this.$route.query
      if (id || turntableId) {
        this.duiwuCouponId = id
        api.command.getList.call(this, {
          url: '/userDuiwuCoupon/page',
          current: 1,
          paramsValue: {
            duiwuCouponId: id ? id : '',
            turntableId: turntableId ? turntableId : '',
          },
        })
        getAction(
          `/userDuiwuCoupon/getStatisticData?duiwuCouponId=${id ? id : ''}&turntableId=${
            turntableId ? turntableId : ''
          }`
        ).then((result) => {
          let dataArr = Object.keys(result.data)
          let entriesArr = Object.values(result.data)
          dataArr.forEach((e, i) => {
            this.dataObj[e] = entriesArr[i] ? entriesArr[i] : 0
          })
          this.$forceUpdate()
        })
      }
    },
    renderTop() {
      const data = [
        {
          name: '领取量',
          value: `${this.dataObj.receivedTotal}/${this.dataObj.giveNum}（${this.dataObj.receivedTotalPercentage}%）`,
        },
        {
          name: '使用量',
          value: `${this.dataObj.usedNum}/${this.dataObj.receivedTotal}（${this.dataObj.usedPercentage}%）`,
        },
        {
          name: '本周领取',
          value: this.dataObj.weekReceivedTotalNum,
        },
        {
          name: '本周使用',
          value: this.dataObj.weekUsedNum,
        },
      ]
      return (
        <div class="home-top">
          {data.map((e) => {
            return (
              <div class="home-top-item" onClick={() => e.onClick()}>
                <div>{e.value}</div>
                <div>{e.name}</div>
              </div>
            )
          })}
        </div>
      )
    },
    getHeader() {
      return [
        {
          name: '中奖人',
          key: 'nickname',
          type: 'input',
        },
        {
          name: '联系方式',
          key: 'mobilePhone',
          type: 'input',
        },
        {
          name: '批次',
          key: 'batchNo',
          type: 'input',
        },
      ]
    },
    getColumns() {
      let _this = this
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-100',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'duiwuCouponId',
          title: '卡号',
          type: 'lt-150',
          customRender: (text, records) => {
            return records.id
          },
          onExport: (text, records) => {
            return records.id
          },
        },
        {
          dataIndex: 'nickname',
          title: '中奖人',
          type: 'lt-150',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                {records.headImg && <img src={records.headImg} />}
                <span>{text}</span>
              </div>
            )
          },
        },
        {
          dataIndex: 'mobilePhone',
          title: '联系方式',
          type: 'lt-100',
        },
        {
          dataIndex: 'giveType',
          title: '发放路径',
          width: 100,
          align: 'left',
          filters: [
            {
              text: '二维码领取',
              value: '0',
            },
            {
              text: '营销活动',
              value: '1',
            },
            {
              text: '数字码兑换',
              value: '2',
            },
          ],
          onExport: (records) => {
            return ['二维码领取', '营销活动', '数字码兑换'][records]
          },
          filterMultiple: false,
          customRender: function (text, records) {
            return {
              0: '二维码领取',
              1: '营销活动',
              2: '数字码兑换',
            }[text]
          },
        },
        {
          dataIndex: 'batchNo',
          title: '批次',
          type: 'lt-100',
          onExport: (text, records) => {
            return records.batchNo || '-'
          },
        },
        {
          dataIndex: 'status',
          title: '使用情况',
          width: 80,
          type: 'badge',
          filters: [
            {
              text: '未使用',
              value: '0',
            },
            {
              text: '已使用',
              value: '1',
            },
          ],
          onExport: (text, records) => {
            return ['未使用', '已使用', '已过期'][text]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 1 ? '已使用' : data == 2 ? '已过期' : '未使用',
              color: data == 1 ? 'red' : 'green',
            }
          },
        },
        {
          dataIndex: 'createTime',
          title: '中奖时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix(),
        },
        {
          dataIndex: 'usedTime',
          title: '使用时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.usedTime).unix() - moment(b.usedTime).unix(),
        },
        {
          title: '操作',
          type: 'buttonGroup',
          width: '15%',
          typeData: ({ records }) => {
            return [
              {
                display: (records.useType == 1 && records.shippingState == 1) || records.usedTime,
                name: records.useType == 1 && records.shippingState == 1 ? '发货' : records.usedTime ? '查看' : '',
                onClick: () => {
                  getAction('/userDuiwuCoupon/detail', { id: records.id }, '/api').then((res) => {
                    if (res.code == 200) {
                      _this.handleStatePopup({
                        detailData: res.data,
                        detailIndex: records.useType == 1 && records.shippingState == 1,
                      })
                    } else {
                      _this.$message.error(res.msg)
                    }
                  })
                },
              },
              {
                display: !records.userId,
                name: '删除',
                type: 'pop',
                popTitle: '确认删除吗?',
                onClick: () => {
                  delAction(`/userDuiwuCoupon/delete?id=${records.id}`, { id: records.id }).then((res) => {
                    message.info('删除成功')
                    this.getData()
                  })
                },
              },
            ].filter((d) => d.display)
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handleStatePopup2()
          },
        },
      ]
    },
    getDrawerForm2(source, formData) {
      const form = [
        {
          form: [
            {
              name: '新增卡数',
              type: 'input',
              key: 'giveNum',
              props: {
                placeholder: '请输入',
                min: 0,
              },
              rules: [
                {
                  required: true,
                },
              ],
            },
          ],
        },
      ]
      return form
    },
    handleStatePopup2(
      source = {
        giveNum: '',
      }
    ) {
      let that = this
      apiTool.showDrawer({
        title: '添加',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm2(source, formData),
          form: source,
          formProps: {
            layout: 'horizontal',
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          },
        },
        success: ({ data, setHidden }) => {
          const { id } = that.$route.query
          putAction(`/userDuiwuCoupon/saveBatchNo`, { giveNum: data.giveNum, id }).then((res) => {
            message.info('添加成功')
            that.getData()
            setHidden()
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    handleStatePopup({ detailData, detailIndex }) {
      let that = this
      apiTool.showDrawer({
        title: '编辑',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(detailData, formData, detailIndex),
          form: detailData,
          formProps: {
            layout: 'horizontal',
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          },
        },
        success: ({ data, setHidden }) => {
          that.getData()
          setHidden()
        },
        foot: ({ close, submit }) => {
          return [
            {
              display: true,
              name: detailIndex ? '取消' : '返回',
              onClick: close,
            },
            {
              display: detailIndex,
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ].filter((e) => e.display)
        },
      })
    },
    getDrawerForm(source, formData, detailIndex) {
      const form = [
        {
          display: true,
          title: '中奖信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '中奖名称',
                  type: 'text',
                  value: source.prizeName,
                },
                {
                  name: '中奖位置',
                  type: 'text',
                  value: source.moduleName,
                },
                {
                  name: '中奖时间',
                  type: 'text',
                  value: source.createTime,
                },
                {
                  name: '使用情况',
                  type: 'text',
                  value: {
                    0: '未使用',
                    1: '已使用',
                  }[source.status],
                },
              ],
            },
          ],
        },
        {
          display: true,
          title: '中奖人',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '中奖人',
                  type: 'text',
                  value: source.nickname,
                },
                {
                  name: '电话',
                  type: 'text',
                  value: source.mobilePhone,
                },
              ],
            },
          ],
        },
        {
          display: true,
          title: '配送',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '收件人',
                  type: 'text',
                  value: source.receiverName,
                },
                {
                  name: '收货人电话',
                  type: 'text',
                  value: source.receiverPhone,
                },
                {
                  name: '收货地址',
                  type: 'text',
                  value: `${
                    source.receiverProvince + source.receiverCity + source.receiverRegion + source.receiverDetailAddress
                  }`,
                },
              ],
            },
          ],
        },
        {
          display: detailIndex,
          title: '标记',
          form: [
            {
              name: '快递公司',
              type: 'input',
              key: 'expressCompany',
            },
            {
              name: '运 单 号',
              type: 'input',
              key: 'expressNumber',
            },
            {
              name: '记录问题',
              type: 'textArea',
              key: 'remark',
            },
          ],
        },
        {
          display: !detailIndex,
          title: '标记详情',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '操作员',
                  type: 'text',
                  value: source.hxAdminName,
                },
                {
                  name: '操作时间',
                  type: 'text',
                  value: source.hxDate,
                },
                {
                  name: '快递公司',
                  type: 'text',
                  value: source.expressCompany,
                },
                {
                  name: '运单号',
                  type: 'text',
                  value: source.expressNumber,
                },
                {
                  name: '记录问题',
                  type: 'text',
                  value: source.remark,
                },
              ],
            },
          ],
        },
      ].filter((e) => e.display)
      return form
    },
  },
  render() {
    return (
      <div>
        {this.renderTop()}
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          records={this.records}
          button={this.getButton()}
        />
      </div>
    )
  },
}
</script>

<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.home-top {
  display: flex;
  align-items: center;
  height: 101px;
  width: 100%;
  background: white;
  margin: 10px 0;
  .home-top-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    &:not(:last-child)::after {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 1px;
      background: rgb(229, 231, 233);
      content: '';
    }
  }
}
/deep/.home-top-item {
  div {
    &:nth-of-type(2) {
      font-size: 14px;
      color: #515a6e;
    }
  }
}
</style>
